import React from "react"
import { graphql } from 'gatsby'
import MainLayout from 'src/layouts/Main'
import PageTitleHero from 'components/PageTitleHero'
import PortfolioCard from 'components/PortfolioCard'


export default ({data}) => {

    const __ = data.allPortfolioJson.edges

    const cards = ()=> {
        return __.map((_, i)=> {
            return (
                <PortfolioCard 
                    key={i}
                    imageURL={_.node.imageURL}
                    title={_.node.title}
                    description={_.node.description}
                    repo={_.node.repo}
                    liveSiteURL={_.node.liveSiteURL}
                />
            )
        })
    }
    return(
    <MainLayout>
        <PageTitleHero
            title="My <span>Work</span>"
            class="portfolio-hero"
        />
        <div className="portfolio-cards">
            <div className="level-9-margin-top flex-content-container portcards">
                {cards()}
            </div>
        </div>
    </MainLayout>
    )
}

export const pageQuery = graphql`
  query {
    allPortfolioJson (sort: { fields: [itemNumber], order: ASC }) {
      edges {
        node {
            itemNumber
            imageURL
            title
            description
            repo
            liveSiteURL
        }
      }
    }
  }
`

