import React from 'react';
import { Jumbotron, Container } from 'reactstrap';

const PageTitleHero = (props) => {
  return (
    <div>
      <Jumbotron className={props.class} fluid>
        <Container fluid>
          <div className="jumbotron-heading-container">
          <h1 className="hero-title" dangerouslySetInnerHTML={{__html: props.title}}/>
            <p className="lead">{props.subheading}</p>
          </div>
        </Container>
      </Jumbotron>
    </div>
  );
};

export default PageTitleHero;